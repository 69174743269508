.fixed-sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  width: $sidebar-width;
  background-color: $base-dark;
  color: $base-text-alt;

  .sidebar-logo-box {
    width: 100%;
    padding: 5px 0;

    background-color: $base-light;
  }

  .sidebar-user-box {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    .inner-box {
      padding: 15px;
      height: 50px;
      background-color: $base;

      img {
        border: 2px solid $base-white;
        margin: 10px;
      }
    }
  }
  .sidebar-user-box:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -10px;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $base;
  }
  .sidebar-content-box {
    padding: 15px;
    width: 100%;
  }
  .sidebar-links-box {
    position: absolute;

    width: 100%;
    bottom: 80px;



    a, a:hover, a:visited, a:active, a:focus{
      padding: 10px 15px;
      border-top: 1px $base-dark-grey solid;

      color: $base-text-alt;
      text-decoration: none;

      .link-icon {
        padding: 3px 0;
        color: $base-dark-grey;
      }
      .link-icon.left {
        float: left;
        padding-right: 11px;
      }
      .link-icon.right {
        float: right;
        padding-left: 11px;
      }
    }
  }
  .sidebar-footer-box {
    position: absolute;

    width: 100%;
    height: 80px;
    bottom: 0;
    padding: 15px;

    background-color: $base;

    text-align: center;
    font-size: 12px;

    a, a:hover, a:active, a:focus, a:visited {
      margin-top: 5px;
      color: $base-white;
    }
  }
}

.plain-active {
  background-color: #DDECF6;
}

.main-content {
  position: absolute;
  overflow-y: auto;

  bottom: 0;
  top: 0;
  right: 0;
  left: $sidebar-width;

  background-color: $base-white;
}