.header {
  height: 72px;
  margin-bottom: 30px;

  background-color: $base-light-grey;

  .user-block {
    float: left;
    padding: 16px 30px;

    color: $base-text;

    strong {
      display: block;
    }
  }

  .header-links {
    display: block;
    float: right;

    .link-block {
      display: inline-block;
      float: left;

      width: 115px;
      height: 100%;

      border-left: 1px solid $base-grey;

      a i {
        display: block;
        padding: 14px 0 5px;

        font-size: 18px;
      }
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 0 8px;

        text-align: center;
        color: $base-text;

        line-height: 1.2;
        font-size: 13px;
        font-weight: bold;
      }
      a:focus, a:hover, a:visited {
        color: $base-text;
        text-decoration: none;

      }
      a.active {
        background-color: $base-white;
      }
    }
  }
}

.dashboard-icon {
  color: $dashboard-icon;
}

.dossier-icon {
  color: $dossier-icon;
}

.progress-icon {
  color: $progress-icon;
}

.exercises-icon {
  color: $exercises-icon;
}

.agenda-icon {
  color: $agenda-icon;
}

.history-icon {
  color: $history-icon;
}

.notes-icon {
  color: $notes-icon;
}

