input, select, textarea {
  display: block;
  width: 100%;
  padding: 5px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
input, select {
  height: 34px;
}
input:focus, select:focus{
  border-color: $base-grey;
  stroke: none;
  box-shadow: none;
}

input.dark, select.dark {
  color: $base-text;
  background-color: $base-light-grey;
}
.form-group{
  margin-bottom: 20px;
}
.form-group.has-error{
  input, select{
    border: $state-danger-text 1px solid;
  }
}
input[type="checkbox"] {
  display: inline;
  width: 20px;
  height: 20px;
  margin: 10px;
}

.thumbnails {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}

.thumbnails:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.image-text {
  text-align: justify;
  width: 150px;
}

.image-text img {
  display: block;
  margin: 0 auto;
}