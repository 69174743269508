.panel {
    position: relative;

    -moz-border-radius: $panel-radius;
    -webkit-border-radius: $panel-radius;
    border-radius: $panel-radius;

    min-height: 270px;

    padding: 0px;
    border: 1px solid $base-grey;

    background-color: $base-white;
    color: $base-text;

    .panel-title {
        display: block;
        padding: 10px;

        -webkit-border-top-left-radius: $panel-radius;
        -moz-border-top-left-radius: $panel-radius;
        border-top-left-radius: $panel-radius;

        -webkit-border-top-right-radius: $panel-radius;
        -moz-border-top-right-radius: $panel-radius;
        border-top-right-radius: $panel-radius;

        border-bottom: 1px solid $base-grey;

        text-align: center;
        text-transform: uppercase;

        background-color: $dashboard-background;
        color: $dashboard-text;

        .panel-title-icon {
            border-radius: 2px;
            padding: 5px 6px;
            background-color: $dashboard-button;
            color: $dashboard-button-text;
        }
        .panel-title-icon.left {
            float: left;
        }
        .panel-title-icon.right {
            float: right;
        }
        .panel-title-icon.alt {
            background-color: $base-white;
            color: $base-text-grey;
        }
    }
    .panel-content {
        position: absolute;
        top: 41px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 30px 20px 0;
        overflow-y: auto;

        .panel-half {
            float: left;

            width: 50%;
            height: 100%;
            padding: 20px;

            overflow-y: auto;
        }
        .panel-half:last-child {
            border-left: 1px solid $base-grey;
        }

        .table-heading {
            height: 30px;
            width: 100%;
            padding: 5px 10px;

            font-size: 12px;

            background-color: $base-light-grey;
            span {
                margin: 0 5px;
            }

            a {
                height: 20px;
                padding: 5px 10px;
                float: left;
                box-sizing: content-box;
                display: inline-block;

                &.active {
                    background-color: white;
                }
            }

            .achieved {
                color: $exercises-icon;
            }
        }
        .table-box {
            width: 100%;
            padding: 15px;
            border-bottom: 1px solid $base-grey;

            table {
                width: 100%;
                color: $base-text;
                font-size: 14px;

                tr {
                    .target-result {
                        text-align: right;
                        font-weight: bold;
                    }

                    .achieved-result {
                        color: $exercises-icon;
                        text-align: right;
                        font-weight: bold;
                    }

                    td:nth-of-type(1) {
                        width: 40%;
                    }
                    td:nth-of-type(2) {
                        width: 30%;
                    }
                    td:nth-of-type(3) {
                        width: 15%;
                    }
                    td:nth-of-type(4) {
                        width: 15%;
                    }

                }
            }
        }
        .table-box:hover {
            background-color: $exercises-background;
        }
    }
    .panel-action {
        position: absolute;
        bottom: 20px;
        padding: 0 20px;
    }
    .btn {
        background-color: $dashboard-button;
        color: $dashboard-button-text;
    }
    form {
        margin: 0;
    }
}

.panel.panel-actionable {
    .panel-content {
        bottom: 70px;
    }
}

.panel.panel-dossier {
    .panel-title {
        background-color: $dossier-background;
        color: $dossier-text;

        .panel-title-icon {
            background-color: $dossier-button;
            color: $dossier-button-text;
        }
    }
    .btn {
        min-width: 240px;
        background-color: $dossier-button;
        color: $dossier-button-text;

    }
    .calendar-image-box {
        background-image: url('/img/calendar.png');
    }
    .day, .month {
        color: $dossier-text;
    }
}

.panel.panel-progress {
    .panel-title {
        background-color: $progress-background;
        color: $progress-text;

        .panel-title-icon {
            background-color: $progress-button;
            color: $progress-button-text;
        }
    }
    .btn {
        min-width: 240px;
        background-color: $progress-button;
        color: $progress-button-text;
    }
    .calendar-image-box {
        background-image: url('/img/calendar.png');
    }
    .day, .month {
        color: $progress-text;
    }
}

.panel.panel-exercises {
    .panel-title {
        background-color: $exercises-background;
        color: $exercises-text;

        .panel-title-icon {
            background-color: $exercises-button;
            color: $exercises-button-text;
        }
    }
    .btn {
        min-width: 240px;
        background-color: $exercises-button;
        color: $exercises-button-text;
    }
    .calendar-image-box {
        background-image: url('/img/calendar.png');
    }
    .day, .month {
        color: $exercises-text;
    }
}

.panel.panel-history {
    .panel-title {
        background-color: $history-background;
        color: $history-text;

        .panel-title-icon {
            background-color: $history-button;
            color: $history-button-text;
        }
    }
    .btn {
        min-width: 240px;
        background-color: $history-button;
        color: $history-button-text;
    }
    .calendar-image-box {
        background-image: url('/img/calendar.png');
    }
    .day, .month {
        color: $history-text;
    }
}

.panel.panel-agenda {
    .panel-title {
        background-color: $agenda-background;
        color: $agenda-text;

        .panel-title-icon {
            background-color: $agenda-button;
            color: $agenda-button-text;
        }
    }
    .btn {
        min-width: 240px;
        background-color: $agenda-button;
        color: $agenda-button-text;
    }
    .calendar-image-box {
        background-image: url('/img/calendar.png');
    }
    .day, .month {
        color: $agenda-text;
    }
}

.panel.panel-notes {
    .panel-title {
        background-color: $notes-background;
        color: $notes-text;

        .panel-title-icon {
            background-color: $notes-button;
            color: $notes-button-text;
        }
    }
    .btn {
        min-width: 240px;
        background-color: $notes-button;
        color: $notes-button-text;
    }
    .calendar-image-box {
        background-image: url('/img/calendar.png');
    }
    .day, .month {
        color: $notes-text;
    }
}

.pagination {
    text-align: center;
    padding: 3px;

    li {
        display: inline;
        border: none;
    }

}

@media (min-width: 769px) and (max-width: 1024px) {

    .panel.panel-dossier,
    .panel.panel-exercises,
    .panel.panel-history,
    .panel.panel-progress {
        .btn {
            min-width: 220px;
        }
    }

    .panel {
        .panel-action {
            padding: 0 10px;
        }
    }

    .panel {
        .panel-content {
            .table-box {
                table {
                    font-size: 12px;
                }
            }
        }
    }
}
