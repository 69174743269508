.inline{
  display: inline !important;
}
.inline-block{
  display: inline-block !important;
}
.block{
  display: block !important;
}
.no-padding{
  padding: 0 !important;
}
.no-margin{
  margin: 0 !important;
}
.no-border{
  border: 0 !important;
}
.align-left{
  text-align: left;
}
.align-right{
  text-align: right;
}
.align-center{
  text-align: center;
}
.full-width{
  width: 100%;
}
.flat {
  min-height: 0 !important;
  min-width: 0 !important;
}