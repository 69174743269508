/*!
 * Knubb dashboard styling
 * Created by Tomas Pieters (TomasDePomas), Cell[0]
 * on 26 jan. 2016
 */
@import "bootstrap/variables";
@import "knubb-dashboard/variables";
@import "knubb-dashboard/helpers";
@import "knubb-dashboard/forms";
@import "knubb-dashboard/panels";
@import "knubb-dashboard/sidebar";
@import "knubb-dashboard/header";

body {
  background-color: $base-light;
  color: $base-text-alt;
  font-family: 'Open Sans', sans-serif;
}

p {
  margin: 0;
}

a, a:hover, a:visited, a:active, a:focus {
  color: $base-link-text;
  cursor: pointer;
}

a.small {
  font-size: 12px;
}

a.plain, a.plain:hover, a.plain:visited, a.plain:active, a.plain:focus {
  display: block;

  color: $base-text;
  text-decoration: none;
}

ul.small-list li {
  min-height: 28px;
  padding: 5px 10px;
}

.btn {
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;

  background-color: $base-button;
  color: $base-button-text;

  cursor: pointer;

  .icon-right {
    float: right;
    padding: 3px;

    font-size: 13px;
  }
}

.btn + .btn {
  margin-top: 7px;
}

.btn.active {
  background-color: $base-button-active;
  color: $base-button-active-text;
}

.btn:hover, .btn:visited, .btn:active, .btn:focus {
  color: $base-button-text;
}

.btn.active {
  color: $base-button-active-text;
}

.btn.active:hover, .btn.active:visited, .btn.active:active, .btn.active:focus {
  color: $base-button-active-text;
}

img.logo-big {
  display: block;
  width: 300px;
  height: 119px;
  margin: 20px auto;
}

img.logo {
  display: block;
  width: 155px;
  height: 62px;
  margin: auto;
}

img.user-img {
  float: left;
  height: 40px;
  width: 40px;
  margin-right: 15px;

}

.user-name {
  font-weight: bold;
}

.icon-list {
  line-height: 1.8;

  .list-item {
    .icon {
      float: left;
      padding: 5px 9px 5px 0;
    }
    p {
      display: inline-block;
    }
  }
}


@media (min-width: 980px) {
  .main-content {
    .container-fluid {
      position: absolute;
      top: 100px;
      bottom: 0;
      right: 0;
      left: 0;

      .half-height {
        height: 50%;
        min-height: 400px;
        padding-bottom: 20px;
        .panel {
          height: 100%;
        }
      }
      .full-height {
        height: 100%;
        min-height: 400px;
        padding-bottom: 20px;
        .panel {
          height: 100%;
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  body,
  p {
    font-size: 13px;
  }
}

@media (max-width: 980px) {
  .panel {
    margin: 30px 0;
    height: 400px;
  }
  .fixed-sidebar {
    width: 180px;
  }
  .main-content {
    left: 180px;
  }
  .user-block {
    display: none;
  }
}

ul {
  padding: 0;
  list-style: none;
}

li {
  padding: 10px;
  border-bottom: 1px solid $base-grey;
  min-height: 90px;

  img {
    border-radius: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1083px) {
  li{
    min-height: 120px;
  }
}

li:hover {
  background-color: $exercises-background;
}

.exercise-image-box {
  position: relative;
  float: left;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  height: 60px;
  width: 60px;
  margin-right: 30px;
  margin-bottom: 10px;

  .badge {
    position: absolute;
    top: 10px;
    left: 60px;

    padding: 0 5px;
    border-radius: 50%;

    background: $exercises-button;
    color: $exercises-button-text;
  }
}

.calendar-image-box {
  position: relative;
  float: left;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  height: 60px;
  width: 60px;
  margin-right: 30px;

  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;

  .day {
    position: absolute;
    left: 0;
    right: 0;
    top: 2px;

    text-align: center;
    font-weight: bold;
    font-size: 25px;
  }
  .month {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;

    text-transform: uppercase;
    text-align: center;
  }
}

::-webkit-scrollbar {
  width: ( $scrollbar-width * 2 ) + $scrollbar-spacing;
  height: ( $scrollbar-width * 2 ) + $scrollbar-spacing;
}

::-webkit-scrollbar-track {
  border: $scrollbar-spacing solid rgba(0, 0, 0, 0);

  -webkit-border-radius: $scrollbar-width * 2;
  -moz-border-radius: $scrollbar-width * 2;
  border-radius: $scrollbar-width * 2;

  background-clip: padding-box;
  background-color: $scrollbar-track;
}

::-webkit-scrollbar-thumb {
  border: $scrollbar-spacing solid rgba(0, 0, 0, 0);

  -webkit-border-radius: $scrollbar-width * 2;
  -moz-border-radius: $scrollbar-width * 2;
  border-radius: $scrollbar-width * 2;

  background-clip: padding-box;
  background-color: $scrollbar-thumb;
}

body {
  scrollbar-face-color: $scrollbar-thumb;
  scrollbar-shadow-color: $scrollbar-thumb;
  scrollbar-highlight-color: $scrollbar-thumb;
  scrollbar-3dlight-color: $scrollbar-thumb;
  scrollbar-darkshadow-color: $scrollbar-thumb;
  scrollbar-track-color: $scrollbar-track;
  scrollbar-arrow-color: $scrollbar-track;
  scrollbar-base-color: $scrollbar-thumb;
}